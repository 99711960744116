import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        padding-top: 3em;

        .location {
            font-size: 2em;
            color: ${variables.dpc_fontDark};
            font-weight: 300;
            text-align: center;
        }

        .route-select {
            margin-top: 2em;

            .route-select-headline {
                font-size: 1.2em;
                color: ${variables.dpc_fontDark};
                font-weight: 300;
                text-align: center;
            }
            
            .routes {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 2em;
                flex-wrap: wrap;

                .route {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    border: 3px solid ${variables.redNewPageColor};
                    padding: 1em;
                    min-height: 15em;
                    cursor: pointer;
                    text-align: center;
                    margin: 0 1em 2em 1em;
                    width: 30%;
                    min-height: 32em;
                    justify-content: flex-start;

                    .box-top {
                        position: relative;
                        height: 16em;
                        background-size: cover;
                        background-image: url(/build/assets/images/image.1c1aabe….jpg);
                        background-position: center;
                        width: 100%;

                        .product-image-mask {
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            top: 0;
                            background-color: rgba(0, 0, 0, 0.6);
                        }

                        .route-headline {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            color: white;
                            font-weight: 600;
                            font-size: 2.25em;
                        }
                    }

                    .box-bottom {
                        color: #FC2B1B;
                        margin-top: 1em;
                        width: 100%;

                        .box-bottom-price {
                            font-weight: 600;

                            p {
                                font-size: 2.5em;
                                line-height: 2em;
                                text-align: center;
                            }
                                
                            .box-price {
                                font-size: 2em;
                                display: inline-block;
                                vertical-align: middle;
                                font-weight: 600;
                                padding: 0 .2em;
                                padding-bottom: .2em;
                            }
                        }
            
                        .separator {
                            border: 0.06em solid;
                            width: 80%;
                        }

                        .description-container {
                            .description {
                                .route-hint {
                                    font-weight: 600;
                                    text-align: left;
                                    width: 80%;
                                    margin: 0 auto;
                                    margin-top: 2em;
                                }
                            }
                        }
                    }

                    .route-hint {
                        color: ${variables.dpc_fontDark};
                        font-size: 1em;
                        font-weight: 300;
                        margin-top: 1em;

                        &.route-hint-alert {
                            color: #DD3544;
                            font-weight: 600;
                        }
                    }

                    &.disabled {
                        cursor: default;
                        background: #403f3f;

                        &:hover {
                            background: ${variables.dpc_backgroundDarkLight};
                            border-color: #666666;
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.tabletL}) {
            .route-select {
                .routes {
                    .route {
                        min-height: 7em;
                        width: 100%;
                    }
                }
            }
        }
     `;