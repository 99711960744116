import { connect } from 'react-redux';
import Component from './component';

import { list } from 'Redux/modules/public/products/actions';

export default connect(
    state => ({
        products: state.publicProducts.list,
        currentLanguage: state.user.currentLanguage,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
        },
    }),
)(Component);
