import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PUBLIC_RESERVATIONS_SELECT_PRODUCT } from 'Consts/components';
import { PRODUCT_VISIBILITY_HIDDEN } from 'Consts/products';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { translate } from 'Utils/language';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';

export default class PublicReservationsProductSelectForm extends Component {
    static propTypes = {
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        onSelect: PropTypes.func.isRequired,
        currentLanguage: PropTypes.string,
        predefinedQuery: PropTypes.object,
        products: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    description: PropTypes.string,
                })
            ).isRequired,
        }).isRequired,
        selectedLocation: PropTypes.object,
    };
    static defaultProps = {
        selectedLocation: null,
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        perPage: 999,
        visibility: PRODUCT_VISIBILITY_HIDDEN,
        timestamp: undefined,
        // eslint-disable-next-line react/destructuring-assignment
        ...(this.props.predefinedQuery || {}),
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        actions.list({ ...queryObject, orderBy: 'order' });
    }

    render() {
        const { products, onSelect, currentLanguage, location, selectedLocation } = this.props;

        if(products.elements.length === 1) {
            onSelect(products.elements[0]);
        }

        return (
            <StyledComponent
                className="component-public-reservations-product-select-form"
                styles={require('./styles')}
            >
                {selectedLocation && (
                    <div className="location">
                        {selectedLocation.name}
                    </div>
                )}
                <div className="route-select">
                    {(!products || (products && products.isLoading))
                        ? (<Spinner />)
                        : (
                            <>
                                <h2 className="route-select-headline">
                                    {translate(`${PUBLIC_RESERVATIONS_SELECT_PRODUCT}_choose_event`, currentLanguage, location)}:
                                </h2>
                                <div className="routes">
                                    {products.elements.length
                                        ? products.elements
                                            .sort((a, b) => a.displayDurationValue - b.displayDurationValue)
                                            .map((product) => (
                                                <a
                                                    key={product.id}
                                                    className="route"
                                                    onClick={() => { onSelect(product); }}
                                                >
                                                    <div className="box-top" style={{ backgroundImage: product.image ? `url(${product.image.imageUrl})` : `url(${require('Theme/images/placeholders/image.jpg')})`}}>
                                                        <div className="product-image-mask"/>
                                                        <h3 className="route-headline">{product.name}</h3>
                                                    </div>
                                                    <div className="box-bottom">
                                                        <div className="box-bottom-price">
                                                            <p>
                                                                <span className="box-price"> {product.priceGross} </span>
                                                                zł
                                                            </p>
                                                        </div>
                                                        <hr className="separator"/>
                                                        <div className="description-container">
                                                            <div className="description">
                                                                {product.description && (<p className="route-hint">{product.description}</p>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            ))
                                        : (
                                            <h3>
                                                {translate(`${PUBLIC_RESERVATIONS_SELECT_PRODUCT}_no_events`, currentLanguage, location)}
                                            </h3>
                                        )
                                    }
                                </div>
                            </>
                        )}
                </div>
            </StyledComponent>
        );
    }
}
